<template>
  <v-card :color="sColor" flat v-on="$listeners">
    <v-card-title
      v-if="sInvoiceTypeName"
      class="justify-center text-body-1 primary--text"
    >
      <span v-text="sInvoiceTypeName" />
    </v-card-title>
    <v-card-title :class="sClassRow">
      <div class="text-h6">
        {{ item.order_serial }}
        {{ item.order_number }}
      </div>

      <div class="text-body-2 grey--text">
        <span v-text="sSignAt" />
      </div>
    </v-card-title>
    <v-card-text>
      <div :class="sClassRow">
        <div
          class="text-subtitle-2 font-weight-bold"
          v-text="$t('amount.total')"
        />
        <div class="text-body-1 font-weight-bold text-right" v-text="sTotal" />
      </div>
      <div :class="sClassRow">
        <div
          class="text-subtitle-2 font-weight-bold"
          v-text="$t('invoice.balance')"
        />
        <div
          class="text-body-1 font-weight-bold text-right"
          v-text="sBalance"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { InvoiceData } from "@planetadeleste/vue-mc-gw";
import { currencyFormat } from "@/plugins/helpers";
import { get } from "lodash";
import { number } from "mathjs";

@Component
export default class InvoiceCard extends Vue {
  @Prop(Object) readonly item!: InvoiceData;

  get sTotal() {
    return currencyFormat(
      this.item.total_price_value,
      this.item.currency?.code
    );
  }

  get sBalance() {
    return currencyFormat(
      get(this.item, "balance_value", 0),
      this.item.currency?.code
    );
  }

  get sSignAt() {
    return `${this.$dayjs(this.item.sign_at).format("L")} ${this.$dayjs(
      this.item.sign_at
    ).format("HH:mm:ss")}`;
  }

  get sClassRow() {
    return "d-flex justify-space-between";
  }

  get sInvoiceTypeName() {
    return get(this.item, "invoice_type.short_name");
  }

  get isCurrent() {
    return (
      this.$route.name === "invoices.update" &&
      number(this.$route.params.id) === this.item.id
    );
  }

  get sColor() {
    return this.isCurrent ? "blue lighten-5" : "transparent";
  }
}
</script>
